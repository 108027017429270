<script setup>
import homePlaceHolderEn from '@/../img/home/home-placeholder-en.png?metadata&webp';
import homePlaceHolderMobileEn from '@/../img/home/home-placeholder-mobile-en.png?metadata&webp';
import homePlaceHolderMobile from '@/../img/home/home-placeholder-mobile.png?metadata&webp';
import homePlaceHolder from '@/../img/home/home-placeholder.png?metadata&webp';
import Img from '@/Components/Img.vue';
import PublicLayout from '@/Layouts/PublicLayout.vue';
import { usePage } from '@inertiajs/vue3';
import { computed } from 'vue';
import Customers from './Customers.vue';
import FAQs from './FAQs.vue';
import Flexable from './Flexable.vue';
import SignupForm from './SignupForm.vue';
import ThanksMessage from './ThanksMessage.vue';
import USPS from './USPS.vue';

defineProps({
    displaying: String,
    tag: String,
    age: String,
    wage: String,
});

const emits = defineEmits(['update:displaying']);

const setDisplaying = (newValue) => {
    emits('update:displaying', newValue);
};

const locale = computed(() => usePage().props.locale);
</script>

<template>
    <PublicLayout title=" ">
        <div class="container grid mb-20 lg:grid-cols-16">
            <div class="lg:col-span-8 lg:mr-10 lg:my-30">
                <slot v-if="displaying === 'Start'"></slot>

                <div v-if="displaying === 'SignupForm'">
                    <slot></slot>
                    <SignupForm :age="age" :tag="tag" @submissionSuccessful="setDisplaying('Thanks')" />
                </div>

                <ThanksMessage :tag="tag" :wage="wage" v-if="displaying === 'Thanks'" />
            </div>

            <div class="order-first mb-4 lg:col-span-8 lg:order-last lg:mb-0">
                <Img
                    class="hidden lg:block"
                    :image="homePlaceHolderEn"
                    :image-NL="homePlaceHolder"
                    alt="flexable"
                    minWidth="1024px"
                />
                <templatev v-if="displaying !== 'Thanks'">
                    <Img
                        class="lg:hidden"
                        :image="homePlaceHolderMobileEn"
                        :image-NL="homePlaceHolderMobile"
                        alt="flexable"
                        maxWidth="1024px"
                    />
                </templatev>
            </div>
        </div>
        <USPS />
        <FAQs />
        <Flexable />
        <Customers />
    </PublicLayout>
</template>
